import React from 'react';
import FieldActionButton from '../FieldActionButton/FieldActionButton';

const AuthenticateButton = ({ authenticateUrl, configurationId, carrierIntegrationName }) => {
  const isDisabled = !authenticateUrl || !configurationId;
  const urlWithId = `${authenticateUrl}?configurationId=${configurationId}`;

  return (
    <div>
      <FieldActionButton
        onClick={() => { if (authenticateUrl) window.location.href = urlWithId; }}
        tooltip={isDisabled ? 'No Authentication URL set' : `Authenticate with ${carrierIntegrationName}`}
        buttonText="Authenticate"
        disabled={isDisabled}
      >
        Authenticate
      </FieldActionButton>
    </div>
  );
};

export default AuthenticateButton;
