/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
// Components
import SelectWithValidation from 'components/SelectWithValidation';
import InputWithValidation from 'components/InputWithValidation';
import DualButtons from 'components/DualButtons';
import IconButton from 'components/IconButton/IconButton';
import AuthenticateButton from 'components/AuthenticateButton/AuthenticateButton';
import Hr from 'components/CardShell/Hr';
// Helpers
import validateConfiguration from 'helpers/validateConfiguration';
// Other
import {
  clearEditConfiguration as clearEditConfigurationAction,
  setEditConfiguration as setEditConfigurationAction,
  updateConfiguration as updateConfigurationAction,
} from 'actions';
import CopyToClipboardIcon from 'images/CopyToClipboard.svg';
// eslint-disable-next-line import/no-extraneous-dependencies
import PencilIcon from '@icons/material/PencilIcon';
import CrossIcon from 'images/cross.svg';
import copy from 'copy-to-clipboard';
import styles from './formStyles.scss';

export class CConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      pageIndex: 0,
    };
  }

  handleCancelClick = () => {
    this.props.clearEditConfiguration();
    this.props.history.push('/settings/carrier-connectors');
  };

  prepareConfigurationForValidation = (configuration) => {
    // In case the secure values are not edited, only the required rule should be kept, the others should be ignored
    // (all the other rules would fail because the form does not contain the actual value, it contains a hidden value)
    const configCopy = JSON.parse(JSON.stringify(configuration));
    configCopy.configuration.integrationProperties.forEach((x) => {
      if (this.isSecure(x) && x.readOnly !== false) {
        x.validationRules = x.validationRules.filter(rule => rule.ruleType === 'required');
      }
    });
    return configCopy;
  }

  handleUpdateClick = () => {
    const configChecked = this.prepareConfigurationForValidation(this.props.editConfiguration);
    const isValid = validateConfiguration(configChecked);
    this.props.setEditConfiguration({ ...this.props.editConfiguration, errors: configChecked.errors });
    this.setState({ submitted: true });

    if (isValid) {
      const integrationValues = this.props.editConfiguration.configuration.integrationProperties.map(x => ({
        fieldName: x.fieldName,
        fieldValue: x.fieldValue,
      }));

      const configuration = {
        ...this.props.editConfiguration.configuration,
        integrationValues,
      };

      this.props.updateConfiguration(configuration);
      this.props.history.push('/settings/carrier-connectors');
    }
  };

  handleChange = (e) => {
    const nameAndValue = ('target' in e)
      ? {
        name: e.target.name,
        value: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      }
      : e;

    let configuration;

    if (nameAndValue.name.startsWith('integrationProperties.')) {
      const name = nameAndValue.name.substr(22);
      const integrationProperties = this.props.editConfiguration.configuration.integrationProperties.map(x => ({
        fieldName: x.fieldName,
        fieldValue: x.fieldName === name ? nameAndValue.value : x.fieldValue,
        fieldTitle: x.fieldTitle,
        fieldType: x.fieldType,
        fieldUrl: x.fieldUrl,
        readOnly: x.readOnly,
        originalValue: x.originalValue === undefined ? x.fieldValue : x.originalValue,
        fieldDescription: x.fieldDescription,
        validationRules: x.validationRules,
      }));

      configuration = {
        ...(this.props.editConfiguration.configuration),
        integrationProperties,
      };
    } else {
      configuration = {
        ...(this.props.editConfiguration.configuration),
        [nameAndValue.name]: nameAndValue.value,
      };
    }

    this.props.editConfiguration.configuration = configuration;

    if (this.state.submitted) {
      const configChecked = this.prepareConfigurationForValidation(this.props.editConfiguration);
      validateConfiguration(configChecked);
      this.props.editConfiguration.errors = configChecked.errors;
    }

    this.props.setEditConfiguration({ configuration, errors: this.props.editConfiguration.errors });
  };

  isRequired = integrationProperty => integrationProperty
    .validationRules.filter(z => z.ruleType === 'required').length === 1;

  isSecure = integrationProperty => integrationProperty
    .validationRules.filter(z => z.ruleType === 'secure').length === 1;

  isRange = integrationProperty => integrationProperty.validationRules.filter(z => z.ruleType === 'range').length === 1;

  options = integrationProperty => integrationProperty.validationRules.filter(z => z.ruleType === 'range')[0]
    .acceptableValues.map(z => ({ label: z, value: z }));

  toggleReadOnly = integrationProperty => {
    const configuration = {
      ...this.props.editConfiguration.configuration,
      integrationProperties:
        this.props.editConfiguration.configuration.integrationProperties.map(item => (item.fieldName !== integrationProperty.fieldName ? item : {
          ...item,
          readOnly: !integrationProperty.readOnly,
          originalValue: integrationProperty.hasOwnProperty('originalValue') ? integrationProperty.originalValue : integrationProperty.fieldValue,
          fieldValue: integrationProperty.readOnly ? '' : integrationProperty.originalValue,
        })),
    };
    this.props.editConfiguration.configuration = configuration;
    this.props.setEditConfiguration({ configuration });
  };

  renderIntegrationProperty = (integrationProperty, errors) => {
    const config = {
      name: `integrationProperties.${integrationProperty.fieldName}`,
      value: integrationProperty.fieldValue,
      label: integrationProperty.fieldTitle,
      errors,
      onChange: this.handleChange,
    };

    if (this.isSecure(integrationProperty)) {
      if (integrationProperty.readOnly === undefined) {
        integrationProperty.readOnly = true;
      }
      let inputType = 'password';
      let inputTag = 'input';

      if (!integrationProperty.readOnly) {
        if (integrationProperty.fieldType === 'text_area') {
          inputType = 'textarea';
          inputTag = 'textarea';
        }
      }

      return (
        <div>
          <InputWithValidation
            type={inputType}
            inputTag={inputTag}
            required={this.isRequired(integrationProperty)}
            {...config}
            action={(
              <IconButton onClick={(ev) => {
                this.toggleReadOnly(integrationProperty);
                ev.currentTarget?.parentNode?.previousSibling?.focus();
              }}
              >
                <PencilIcon
                  data-tip="Edit"
                  className={styles.icon}
                  hidden={!integrationProperty.readOnly}
                />
                <CrossIcon
                  height="20"
                  data-tip="Revert changes"
                  className={styles.icon}
                  hidden={integrationProperty.readOnly}
                />
              </IconButton>
  )}
            disabled={integrationProperty.readOnly}
            tabIndex={integrationProperty.readOnly ? '-1' : null}
          />
          {integrationProperty?.fieldUrl !== null && (
            <AuthenticateButton
              authenticateUrl={integrationProperty.fieldUrl}
              configurationId={this.props.editConfiguration.configuration.id}
              carrierIntegrationName={this.props.editConfiguration.configuration.carrierIntegrationName}
            />
          )}
        </div>
      );
    }

    if (integrationProperty.fieldType === 'text_area') {
      return (
        <InputWithValidation
          inputTag="textarea"
          required={this.isRequired(integrationProperty)}
          {...config}
        />
      );
    }

    return this.isRange(integrationProperty)
      ? (
        <SelectWithValidation
          required
          options={this.options(integrationProperty)}
          {...config}
        />
      )
      : (
        <InputWithValidation
          required={this.isRequired(integrationProperty)}
          type="text"
          {...config}
        />
      );
  }

  render() {
    const {
      editConfiguration,
    } = this.props;

    return (
      <div className={styles.container}>
        <ReactTooltip effect="solid" isCapture />
        <p className={styles.mainTitle}>{editConfiguration.configuration.carrierIntegrationName}</p>
        <Hr />
        <div className={cx(styles.dualSelect, 'mt-3')}>
          <button
            type="button"
            className={
              cx(
                this.state.pageIndex === 0 ? styles.dualSelectButtonActive : '',
                styles.dualSelectButton,
                'detail2',
              )
            }
            onClick={() => this.setState({ pageIndex: 0 })}
          >
            I know my details
          </button>
          <button
            type="button"
            className={
              cx(
                this.state.pageIndex === 1 ? styles.dualSelectButtonActive : '',
                styles.dualSelectButton,
                'detail2',
              )
            }
            onClick={() => this.setState({ pageIndex: 1 })}
          >
            I don&apos;t know my details
          </button>
        </div>
        {this.state.pageIndex === 0 && (
          <div>
            <div className="mt-5">
              <InputWithValidation
                name="name"
                label="Give your connector a name (If required)"
                value={editConfiguration.configuration.name}
                onChange={this.handleChange}
                errors={editConfiguration.errors}
              />
            </div>
            {editConfiguration.configuration.integrationProperties.map(x => (
              <div key={x.fieldName}>
                <div className="mt-4">
                  {this.renderIntegrationProperty(x, editConfiguration.errors)}
                </div>
              </div>
            ))}
            <div className="mt-4">
              <InputWithValidation
                name="CarrierReference"
                label="Carrier Reference"
                value={editConfiguration.configuration.carrierReference}
                action={(
                  <IconButton onClick={() => copy(editConfiguration.configuration.carrierReference)}>
                    <CopyToClipboardIcon
                      height="20"
                      data-tip="Copy to clipboard"
                      className={styles.icon}
                    />
                  </IconButton>
                )}
                disabled
              />
            </div>
            <div>
              {editConfiguration.error}
            </div>
            <div className={`${styles.depad} mt-4`}>
              <DualButtons
                rightFunction={this.handleUpdateClick}
                rightText="Save"
                leftText="Cancel"
                leftFunction={this.handleCancelClick}
                theme="rightPink"
              />
            </div>
          </div>
        )}
        {this.state.pageIndex === 1 && (
          <div>
            {editConfiguration.configuration.carrierEmail && (
              <div>
                <p>Please contact your carrier</p>
                <p>
                  Please contact&nbsp;
                  <a href={`mailTo:${editConfiguration.configuration.carrierEmail};`} className={styles.email}>
                    {editConfiguration.configuration.carrierEmail}
                  </a> and
                  provide them with the following details:
                </p>
                <p>1. Your carrier account number if you have one.</p>
                <p>2. Your name and contact information.</p>
              </div>
            )}
            {!editConfiguration.configuration.carrierEmail && (
              <div>
                <p>You will need the details of your&nbsp;
                  {editConfiguration.configuration.carrierName} account
                  to set up this Carrier Connector.
                </p>
                <p>Please contact&nbsp;
                  {editConfiguration.configuration.carrierName} and
                  request your account details.
                </p>
                <p>When you have this information, click the&nbsp;
                  <b>I know my details</b> tab
                  and follow the setup instructions on-screen.
                </p>
              </div>
            )}
            <div className={`${styles.btnContainer} ${styles.depad}`}>
              <button
                type="button"
                name="create"
                onClick={this.handleCancelClick}
              >Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setEditConfiguration: configuration => dispatch(setEditConfigurationAction(configuration)),
  clearEditConfiguration: () => dispatch(clearEditConfigurationAction()),
  updateConfiguration: configuration => dispatch(updateConfigurationAction(configuration)),
});

export default withRouter(connect(state => ({
  editConfiguration: state.editConfiguration,
}), mapDispatchToProps)(CConfiguration));
